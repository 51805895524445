@use 'abstract' as *

//region Default style
html, body
	padding: 0
	margin: 0

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box
//endregion
h1, h2, h3, h4, h5, h6
	font-size: 1em
	margin: 0
	font-weight: normal

p
	margin: 0

html
	--padding-value: 140px
	--padding-inline-value: var(--padding-value)
	--padding-block-value: 40px
	
	@include zi-media
		--padding-value: 25px

body
	background-image: url('/images/site_background.jpg')
	background-repeat: repeat
	color: $color-bleu-principal
//region Fonts
@font-face
	font-family: 'Inter Tight'
	src: url('./fonts/InterTight-Regular.ttf')
	font-display: swap

@font-face
	font-family: 'Inter Tight Medium'
	src: url('./fonts/InterTight-Medium.ttf')
	font-display: swap

@font-face
	font-family: 'Inter Tight SemiBold'
	src: url('./fonts/InterTight-SemiBold.ttf')
	font-display: swap

@font-face
	font-family: 'Inter Tight Bold'
	src: url('./fonts/InterTight-Bold.ttf')
	font-display: swap

@font-face
	font-family: 'Inter Tight Light'
	src: url('./fonts/InterTight-Light.ttf')
	font-display: swap

@font-face
	font-family: 'Cormorant Garamond Bold'
	src: url('./fonts/CormorantGaramond-Bold.ttf')
	font-display: swap

@font-face
	font-family: 'Cormorant Garamond Bold Italic'
	src: url('./fonts/CormorantGaramond-BoldItalic.ttf')
	font-display: swap

@font-face
	font-family: 'Cormorant Garamond Medium Italic'
	src: url('./fonts/CormorantGaramond-MediumItalic.ttf')
	font-display: swap

html, body
	font-family: "Inter Tight Medium", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	
	> *
		font-size: 1rem
//endregion
//region Form
//region Inputs
label
	position: relative
	
	input, textarea
		width: 100%
		
		padding: 1rem 2rem
		background-color: $color-blanc-casse
		
		border-radius: 10px
		border: none
		
		font-family: inherit
		font-size: inherit
		
		box-shadow: inset 0 0 0 0 $color-bleu-principal
		
		transition: box-shadow .3s ease
		
		~ .label
			opacity: 0
			transition: opacity .3s ease
			
			position: absolute
			bottom: 100%
			left: 1rem
			
			font-family: "Inter Tight Medium", sans-serif
			font-size: 12px
		
		&:placeholder-shown
			font-family: inherit
			font-size: inherit
			color: rgba($color-bleu-principal, .5)
		
		&:hover
			box-shadow: inset 0 0 0 3px $color-bleu-principal
		
		&:focus, &:not(:placeholder-shown)
			outline: none
			
			~ .label
				opacity: .4
	
	.error
		display: none
		
		position: absolute
		right: 1rem
		top: 50%
		transform: translateY(-50%)
		
		flex-direction: row
		gap: 10px
		align-items: center
		
		font-size: 12px
	
	&.error
		color: red
		
		input, textarea
			color: red
			box-shadow: inset 0 0 0 3px red
		
		.error
			display: flex
//endregion
//endregion
//region Page transition
#smooth-content
	overflow: hidden

.page_transition_overlay
	z-index: 100
	
	position: fixed
	inset: 0
	width: 100%
	height: 100%
	
	transform: translateY(-100%)
	visibility: hidden
	
	display: flex
	justify-content: center
	align-items: center
	
	.letter_o
		stroke: white
//endregion
.animation
	// Start of animation
	visibility: hidden
	
	position: fixed
	z-index: 100
	
	height: 100vh
	width: 100%
	
	opacity: 1
	transition: opacity .3s linear
	
	background-image: url('/images/site_background.jpg')
	
	.animation_logo
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%, -50%)
		visibility: hidden
		
		z-index: -1
		
		fill: $color-bleu-principal
		
		.o_and_m
			fill: none
	
	.animation_trait_fin
		position: absolute
		bottom: 50%
		right: 50%
		
		transform: translate(-40px, 14px)
	
	.animation_trait_epais
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -100px)
		min-height: 80vh
		height: 80vh
		width: auto
	
	.animation_enter_trait_epais
		position: absolute
		min-width: 175vw
		top: 50%
		left: 50%
		transform: translate(-50%, -49%)
	
	@include zi-media
		.animation_trait_epais
			width: auto
			min-height: 100vh
			height: 100vh
	
	@include zi-media('<=', mobile)
		.animation_logo
			transform-origin: top left
			scale: .6
		
		.animation_trait_fin
			transform-origin: bottom right
			scale: .6
		
		.animation_trait_epais
			width: auto
			min-height: 85vh
			height: 85vh

.gs-dev-tools
	z-index: 100

@include zi-media
	html
//scroll-behavior: smooth

.no-scroll
	overflow: hidden !important
